"use strict";
/*
 * this module provides a set of methods that are used to parse and serialize
 * wooindex filters back and forth between the deifferent representations of it
 * that are used across the app
 */
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.proposeFileName = exports.stringifySearch = exports.parseSearch = exports.queryfyFilter = exports.normalizeScoreFilter = exports.filterifyQuery = exports.serializeFilter = exports.parseFilter = void 0;
var searchParams_1 = require("./searchParams");
var score_1 = require("./score");
/**
 * Parse a `URLSearchParams` into a `Filter` object
 */
function parseFilter(params) {
    return {
        countries: params.getAll('countries').filter(Boolean),
        technologies: params.getAll('technologies').filter(Boolean),
        criteria: params.getAll('criteria').filter(Boolean),
        categories: params.getAll('categories').filter(Boolean),
        search: params.getAll('search').filter(Boolean),
        topics: params.getAll('topics').filter(Boolean),
        url: params.getAll('url').filter(Boolean),
        score: params.getAll('score').filter(Boolean),
        context: params.getAll('context').filter(Boolean)
    };
}
exports.parseFilter = parseFilter;
/**
 * Searialize a `Filter` into a `URLSearchParams` object
 */
function serializeFilter(filter) {
    var e_1, _a, e_2, _b;
    var searchParams = new URLSearchParams();
    try {
        for (var _c = __values(Object.entries(filter)), _d = _c.next(); !_d.done; _d = _c.next()) {
            var _e = __read(_d.value, 2), name_1 = _e[0], value = _e[1];
            if (Array.isArray(value)) {
                try {
                    for (var value_1 = (e_2 = void 0, __values(value)), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                        var item = value_1_1.value;
                        searchParams.append(name_1, item);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (value_1_1 && !value_1_1.done && (_b = value_1.return)) _b.call(value_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
            else if (value) {
                searchParams.append(name_1, value);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
        }
        finally { if (e_1) throw e_1.error; }
    }
    searchParams.sort();
    return searchParams;
}
exports.serializeFilter = serializeFilter;
/**
 * Parse a node.js style url query into a `Filter` object
 */
function filterifyQuery(query) {
    return parseFilter(searchParams_1.toSearchParams(query));
}
exports.filterifyQuery = filterifyQuery;
/**
 * Normalize `Filter.score` to only integers between 0-100
 */
function normalizeScoreFilter(filter) {
    var _a = score_1.getScoreRange(filter.score[0], filter.score[1]), min = _a.min, max = _a.max;
    if (min === score_1.MIN_SCORE && max === score_1.MAX_SCORE) {
        filter.score = [];
    }
    else {
        filter.score[0] = "" + min;
        filter.score[1] = "" + max;
    }
    return filter;
}
exports.normalizeScoreFilter = normalizeScoreFilter;
/**
 * Searialize a `Filter` into a node.js style url query
 */
function queryfyFilter(filter) {
    var e_3, _a;
    var result = {};
    try {
        for (var _b = __values(Object.entries(filter)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), name_2 = _d[0], value = _d[1];
            if (value && value.length > 0) {
                result[name_2] = value;
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return result;
}
exports.queryfyFilter = queryfyFilter;
/**
 * Parse a human readable string into a `Filter.search` array.
 */
function parseSearch(search) {
    if (!search) {
        return [];
    }
    else if (Array.isArray(search)) {
        return search
            .map(function (term) { return term.trim().replace(/^"+|"+$/g, ''); })
            .filter(Boolean);
    }
    // "splits" on spaces but preserves quoted terms
    var terms = search.match(/[^" ]+|"[^"]*"/g);
    return parseSearch(terms);
}
exports.parseSearch = parseSearch;
/**
 * Serialize a `Filter.search` array into a human readable string
 */
function stringifySearch(search) {
    return search.map(function (term) { return term.includes(' ') ? "\"" + term + "\"" : term; }).join(' ');
}
exports.stringifySearch = stringifySearch;
function formatYYYYMMDD(date) {
    return [
        String(date.getFullYear()).padStart(4, '0'),
        String(date.getMonth() + 1).padStart(2, '0'),
        String(date.getDate()).padStart(2, '0')
    ].join('');
}
function proposeFileName(filter, date) {
    if (date === void 0) { date = new Date(); }
    return __spreadArray(__spreadArray([
        formatYYYYMMDD(date)
    ], __read(filter.countries.slice(0, 3))), __read(filter.technologies.slice(0, 3))).join('-');
}
exports.proposeFileName = proposeFileName;
