"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CRITERIUM_STATUS_DISPLAY_NAME = exports.QUANTIZED_SCORE_DISPLAY_NAME = exports.ATTRIBUTE_DESCRIPTORS = exports.ATTRIBUTES = void 0;
/**
 * Order in which to display attributes
 */
exports.ATTRIBUTES = [
    'url',
    'country',
    'title',
    'technologies',
    'score',
    'emails',
    'phoneNumbers',
    'profilesFacebook',
    'profilesTwitter',
    'profilesInstagram',
    'profilesLinkedIn',
    'profilesYouTube',
    'profilesYelp',
    'profilesFoursquare',
    'profilesEtsy',
    'profilesPinterest',
    'profilesGithub',
    'openGraph',
    'trafficEstimation',
    'backlinksScore',
    'keywords',
    'topics',
    'declaredLanguage',
    'detectedLanguage',
    'encoding',
    'sslSecured',
    'mixedContent',
    'mobileFriendliness',
    'brokenLinks',
    'custom404',
    'robotsTxt',
    'sitemapValidity',
    'schemaOrg',
    'assetCacheability',
    'assetCompression',
    'assetMinification',
    'urlresolve',
    'updatedAt'
];
var ATTRIBUTE_DESCRIPTORS_INTERNAL = {
    url: {
        name: 'Domain',
        description: 'Website address',
        type: 'string'
    },
    score: {
        name: 'Score',
        description: 'The Website score is a numerical analysis of how well optimized the webpage is',
        type: 'number'
    },
    country: {
        name: 'Country',
        description: 'What country is this website from',
        type: 'string'
    },
    title: {
        name: 'Title',
        description: 'The title tag on this web page',
        type: 'string'
    },
    technologies: {
        name: 'Technologies',
        description: 'The technologies used to build this website',
        type: 'list'
    },
    trafficEstimation: {
        name: 'Traffic Estimation',
        description: 'Scoring of the estimated traffic to this website',
        type: 'quantized'
    },
    emails: {
        name: 'Emails',
        description: 'Emails found on this website',
        type: 'list'
    },
    phoneNumbers: {
        name: 'Phone Numbers',
        description: 'Phone numbers found on this website',
        type: 'list'
    },
    declaredLanguage: {
        name: 'Declared Language',
        description: 'The language this web page is declared to be written in',
        type: 'string'
    },
    detectedLanguage: {
        name: 'Detected Language',
        description: 'The main language detected on this web page',
        type: 'string'
    },
    keywords: {
        name: 'Keywords',
        description: 'This data represents the keywords and phrases that this web page appears to be optimized around',
        type: 'list'
    },
    encoding: {
        name: 'Encoding',
        description: 'The language/character encoding this website uses, it can prevent problems with the rendering of special characters',
        type: 'string'
    },
    topics: {
        name: 'Topics',
        description: 'DBpedia topics found on this website',
        type: 'list'
    },
    updatedAt: {
        name: 'Last Updated',
        description: 'Date the attributes for this website was last updated',
        type: 'date'
    },
    openGraph: {
        name: 'Open Graph Protocol',
        description: 'Open Graph tags on this web page',
        type: 'status'
    },
    backlinksScore: {
        name: 'Backlinks score',
        description: 'The backlinks score is an impression of the backlink quality and quantity for this domain',
        type: 'quantized'
    },
    sslSecured: {
        name: 'SSL Secure',
        description: 'Is this website SSL secured',
        type: 'status'
    },
    mixedContent: {
        name: 'Mixed Content',
        description: 'Does this website serve http resources on its https web page',
        type: 'status'
    },
    mobileFriendliness: {
        name: 'Mobile Friendliness',
        description: 'Is this website mobile friendly',
        type: 'status'
    },
    brokenLinks: {
        name: 'Broken links',
        description: 'Broken links on this web page',
        type: 'status'
    },
    custom404: {
        name: 'Custom 404',
        description: 'Does this website serve customised 404 error pages',
        type: 'status'
    },
    robotsTxt: {
        name: 'Robots.txt',
        description: 'Does this website serve a robot.txt file',
        type: 'status'
    },
    sitemapValidity: {
        name: 'Sitemaps Validity',
        description: 'Does this website serve a sitemap file and is it valid',
        type: 'status'
    },
    schemaOrg: {
        name: 'Schema.org',
        description: 'Are schema.org tags on this web page',
        type: 'status'
    },
    assetCacheability: {
        name: 'Asset Cacheability',
        description: 'Are assets (like CSS, Javascript, etc.) correctly cached on this website',
        type: 'status'
    },
    assetCompression: {
        name: 'Asset Compression',
        description: 'Are assets (like CSS, Javascript, etc.) correctly compressed on this website',
        type: 'status'
    },
    assetMinification: {
        name: 'Asset Minification',
        description: 'Are assets (like CSS, Javascript, etc.) correctly minified on this website',
        type: 'status'
    },
    urlresolve: {
        name: 'URL Resolve',
        description: 'Do all http/https and www/non-www versions correctly point to 1 version',
        type: 'status'
    },
    profilesFacebook: {
        name: 'Facebook',
        description: 'Facebook profile(s) found on the website',
        type: 'list'
    },
    profilesTwitter: {
        name: 'Twitter',
        description: 'Twitter profile(s) found on the website',
        type: 'list'
    },
    profilesInstagram: {
        name: 'Instagram',
        description: 'Instagram profile(s) found on the website',
        type: 'list'
    },
    profilesLinkedIn: {
        name: 'LinkedIn',
        description: 'LinkedIn profile(s) found on the website',
        type: 'list'
    },
    profilesYouTube: {
        name: 'YouTube',
        description: 'YouTube profile(s) found on the website',
        type: 'list'
    },
    profilesYelp: {
        name: 'Yelp',
        description: 'Yelp profile(s) found on the website',
        type: 'list'
    },
    profilesFoursquare: {
        name: 'Foursquare',
        description: 'Foursquare profile(s) found on the website',
        type: 'list'
    },
    profilesEtsy: {
        name: 'Etsy',
        description: 'Etsy profile(s) found on the website',
        type: 'list'
    },
    profilesPinterest: {
        name: 'Pinterest',
        description: 'Pinterest profile(s) found on the website',
        type: 'list'
    },
    profilesGithub: {
        name: 'GitHub',
        description: 'GitHub profile(s) found on the website',
        type: 'list'
    }
};
exports.ATTRIBUTE_DESCRIPTORS = ATTRIBUTE_DESCRIPTORS_INTERNAL;
exports.QUANTIZED_SCORE_DISPLAY_NAME = {
    'very-low': 'Very Low',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    'very-high': 'Very High'
};
exports.CRITERIUM_STATUS_DISPLAY_NAME = {
    bad: 'Bad',
    average: 'Average',
    good: 'Good'
};
