import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1em',
    height: '1em',
    fontSize: theme.typography.pxToRem(24),
    backgroundSize: 'contain',
    flexShrink: 0
  },
  /* Styles applied to the root element if `fontSize="inherit"`. */
  fontSizeInherit: {
    fontSize: 'inherit'
  },
  /* Styles applied to the root element if `fontSize="small"`. */
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(20)
  },
  /* Styles applied to the root element if `fontSize={16}`. */
  fontSizeExtraSmall: {
    fontSize: theme.typography.pxToRem(16)
  },
  /* Styles applied to the root element if `fontSize="large"`. */
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(36)
  }
}));

export interface IconProps {
  fontSize?: 'default' | 'inherit' | 'small' | 'large' | 16
  className?: string
}

export default function useIconClass ({ className, fontSize = 'default' }: IconProps = {}) {
  const classes = useStyles();
  return clsx(className, classes.root, {
    [classes.fontSizeInherit]: fontSize === 'inherit',
    [classes.fontSizeExtraSmall]: fontSize === 16,
    [classes.fontSizeSmall]: fontSize === 'small',
    [classes.fontSizeLarge]: fontSize === 'large'
  });
}
