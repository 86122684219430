import { Chip, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useI18n } from './i18n';
import VisibilityCheckbox from './VisibilityCheckbox';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    // Negative margin to counteract the individual tags margin.
    margin: '-4px -8px'
  },
  tag: {
    margin: '4px 8px'
  }
}));

export interface TagsProps<T> {
  value: T[]
  limit?: number
  size?: 'medium' | 'small'
  getLabel?: (item: T) => React.ReactNode
  getIcon?: (utem: T) => React.ReactElement
}

export default function Tags<T> ({
  value = [],
  limit = -1,
  size,
  getLabel = String,
  getIcon
}: TagsProps<T>) {
  const classes = useStyles();
  const { numberFormat } = useI18n();

  const [expanded, setExpanded] = React.useState(false);
  const visibleTags = limit < 0 || expanded ? value : value.slice(0, limit);
  const overLimit = limit >= 0 ? Math.max(value.length - limit, 0) : 0;

  return (
    <div className={classes.root}>
      {visibleTags.map((item, i) => (
        <Chip
          className={classes.tag}
          key={i}
          label={getLabel(item)}
          size={size}
          icon={getIcon && getIcon(item)}
        />
      ))}
      {overLimit > 0
        ? (
          <span className={classes.tag}>
            {expanded ? null : `+${numberFormat.basic.format(overLimit)}`}
            <VisibilityCheckbox
              size='small'
              value={expanded}
              onChange={event => setExpanded(event.target.checked)}
            />
          </span>
          )
        : null}
    </div>
  );
}
