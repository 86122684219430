"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScoreRange = exports.MAX_SCORE = exports.MIN_SCORE = void 0;
exports.MIN_SCORE = 0;
exports.MAX_SCORE = 100;
function getScoreRange(a, b) {
    var valueA = Number(a);
    var valueB = Number(b);
    var max = exports.MAX_SCORE;
    var min = exports.MIN_SCORE;
    if (isNaN(valueA) && isNaN(valueB)) {
        return { min: min, max: max };
    }
    else if (isNaN(valueA)) {
        if (valueB > max)
            return { min: min, max: max };
        if (valueB < min)
            return { min: min, max: min };
        return { min: min, max: Math.floor(valueB) };
    }
    else if (isNaN(valueB)) {
        if (valueA > max)
            return { min: max, max: max };
        if (valueA < min)
            return { min: min, max: max };
        return { min: Math.floor(valueA), max: max };
    }
    else {
        var values = [
            valueA > max ? max : (valueA < min ? min : Math.abs(valueA)),
            valueB > max ? max : (valueB < min ? min : Math.abs(valueB))
        ];
        return {
            min: Math.floor(Math.min(values[0], values[1])),
            max: Math.floor(Math.max(values[0], values[1]))
        };
    }
}
exports.getScoreRange = getScoreRange;
