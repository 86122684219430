import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import useIconClass, { IconProps } from './useIconClass';
import clsx from 'clsx';

interface TechnologyIconProps extends IconProps {
  id: string
  style?: React.CSSProperties
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundRepeat: 'no-repeat'
  }
}));

export default React.forwardRef(function TechnologyIcon (props: TechnologyIconProps, ref: React.ForwardedRef<HTMLSpanElement>) {
  const { id, style, ...rest } = props;
  const classes = useStyles();
  const iconUrl = `${process.env.TECH_ICON_BASE}/${id}.png`;
  const iconClass = useIconClass(props);
  // we need to pass through all props in order for @material-ui/core/Tooltip to work
  return (
    <span
      {...rest}
      ref={ref}
      className={clsx(iconClass, classes.root)}
      style={{ ...style, backgroundImage: `url(${iconUrl})` }}
    />
  );
});
