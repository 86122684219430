import * as React from 'react';
import { Alert } from '@material-ui/lab';
import { Capabilities } from '@wooindex/common/types';
import { Link, useI18n } from './i18n';

interface UpgradePlanCtaContentProps {
  capabilities: Capabilities
}

function UpgradePlanCtaContent ({ capabilities }: UpgradePlanCtaContentProps) {
  const { numberFormat } = useI18n();
  switch (capabilities.seesCtaUpgrade) {
    case 'fromFree': return (
      <>
        Your current plan is restricted to CSV exports of up to{' '}
        <strong>{numberFormat.basic.format(capabilities.maxCsvRows)} rows</strong>{' '}
        and a <strong>limited set of columns</strong>.{' '}
        <Link href='/subscription'>Subscribe to a monthly or yearly plan</Link> to remove these restrictions.
      </>
    );
    case 'fromMonthly': return (
      <>
        Consider <Link href='/subscription'>upgrading to a yearly plan</Link> for the ability to export{' '}
        <strong>Emails and Phone Numbers</strong>{' '}
        and a <strong>20% discount</strong> on your monthly price equivalent.
      </>
    );
    default: return null;
  }
}

interface UpgradePlanCtaProps {
  className?: string
  capabilities: Capabilities
}

export default function UpgradePlanCta ({ capabilities, className }: UpgradePlanCtaProps) {
  return capabilities.seesCtaUpgrade
    ? <Alert severity='info' className={className}><UpgradePlanCtaContent capabilities={capabilities} /></Alert>
    : null;
}
