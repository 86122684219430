import * as React from 'react';
import { useI18n } from './i18n';
import Tags, { TagsProps } from './Tags';
import TechnologyIcon from './TechnologyIcon';

export type TechnologyTagsProps = TagsProps<string>

export default function TechnologyTags ({ value, ...props }: TechnologyTagsProps) {
  const { dictionary } = useI18n();
  return (
    <Tags
      value={value}
      size='small'
      getIcon={id => <TechnologyIcon id={id} fontSize={16} />}
      getLabel={id => dictionary.technology[id] || id}
      {...props}
    />
  );
}
